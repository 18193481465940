<template>
  <div>
    <v-form ref="form" class="my-5 mx-3 font-weight-light">
      <v-text-field
        label="Username *"
        name="name"
        prepend-icon="mdi-account"
        type="text"
        v-model="name"
        :rules="rules.requireCheck"
        autocomplete="off"
        data-cy="auth-su-input-username"
      />
      <v-text-field
        label="Email *"
        name="email"
        prepend-icon="mdi-email"
        type="text"
        v-model="email"
        :rules="rules.emailCheck"
        autocomplete="off"
        data-cy="auth-su-input-email"
      />
      <v-text-field
        label="Password *"
        name="password"
        prepend-icon="mdi-lock"
        v-model="password1"
        :rules="rules.requireCheck.concat(rules.passwordCheck)"
        :append-icon="togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="togglePassword = !togglePassword"
        :type="togglePassword ? 'text' : 'password'"
        data-cy="auth-su-input-password"
      />
      <v-text-field
        label="Confirm Password *"
        name="confirm"
        prepend-icon="mdi-lock-alert"
        v-model="password2"
        :rules="rules.requireCheck.concat(rules.passwordRule)"
        @keyup.enter="onSignUp"
        :append-icon="toggleConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="toggleConfirm = !toggleConfirm"
        :type="toggleConfirm ? 'text' : 'password'"
        data-cy="auth-su-input-confirm"
      />
      <v-text-field
        label="Request to join a Company Profile *"
        name="companyRequest"
        prepend-icon="mdi-domain"
        type="text"
        v-model="company_request"
        autocomplete="off"
        :rules="rules.requireCheck"
        data-cy="auth-su-input-company-request"
      />
      <span class="info--text">Let us know your company's name so FixturFab can add you to a Company Profile. Otherwise, type <span class="font-italic font-weight-bold">Personal Project.</span></span>
    </v-form>
    <v-row class="px-5 my-5">
      <v-btn
        dark
        class="font-weight-medium propercase"
        color="primary"
        @click="onSignUp"
        block
        data-cy="auth-su-sign-up-button"
      >
        Sign up
      </v-btn>
    </v-row>
    <v-row class="mx-3">
      <p>
        Already have an account?
        <span
          class="pl-2 font-weight-regular moduleLink"
          @click="gotoLink('signin')"
         data-cy="auth-su-sign-in-button"
          >Sign In!</span
        >
      </p>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      togglePassword: false,
      toggleConfirm: false,
      show: false,
      name: null,
      email: null,
      password1: null,
      password2: null,
      company_request: null,
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
        emailCheck: [
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Email is invalid";
          },
        ],
        passwordCheck: [
          (v) =>
            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*(){}<>?]).{8,}/.test(v) ||
            "Password must contain uppercase and lowercase letters, a number and a special character.",
        ],
        passwordRule: [
          (v) =>
            v === this.password1 || "Password does not match.",
        ],
      },
    };
  },
  methods: {
    gotoLink(link) {
      this.$router.push({ name: link });
    },
    async onSignUp() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const actionPayload = {
        username: this.name,
        email: this.email,
        password1: this.password1,
        password2: this.password2,
        company_request: this.company_request,
      };

      try {
        const response = await this.$store.dispatch(
          "auth/signUp",
          actionPayload
        );
        this.$store.commit("ui/SNACK_BAR", response);
        this.$router.replace("/auth");
      } catch (err) {
        this.$store.commit(
          "ui/SNACK_BAR",
          err.message || "Failed to authenticate, try later."
        );
      }
    },
  },
};
</script>
<style scoped></style>
